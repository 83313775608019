<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <!--页面主体-->
    <section class="main">
      <div class="container">
        <div id="stepbox" class="stepbox two">
          <div class="line"></div>
          <div class="now"></div>
          <div class="step stepone ok">
            <span><i class="icon-stepone"></i></span>
            <p>1、选择票种</p>
          </div>
          <div class="step steptwo ok">
            <span><i class="icon-steptwo"></i></span>
            <p>2、选择预订消费日期填写游客信息</p>
          </div>
          <div class="step stepthree">
            <span><i class="icon-stepthree"></i></span>
            <p>3、提交行程单等待审核</p>
          </div>
          <div class="step stepfour">
            <span><i class="icon-stepfour"></i></span>
            <p>4、确认支付</p>
          </div>
          <div class="step five">
            <span><i class="icon-stepfive"></i></span>
            <p>5、购票成功</p>
          </div>
        </div>
        <div class="box datebox">
          <div class="title"><span>预订消费日期</span></div>
          <div class="content">
            <div class="date">
              <el-form>
                <el-form-item label="选择消费日期">
                  <el-date-picker
                    type="date"
                    v-model="saveInfo.bookUseDate"
                    :disabled-date="disabledDate"
                    placeholder="请选择消费日期"
                    value-format="YYYY-MM-DD"
                    :disabled="itineraryId"
                    @change="queryDisGroupAndCommditys()"
                  ></el-date-picker>
                </el-form-item>
              </el-form>
            </div>
            <!--<div class="section" v-if="timeArrShow.length && !itineraryId">
              <div class="wrap">
                <h1>【乐园门票】需要选择入园时间</h1>
                <div class="list">
                  <a href="javascript:;" v-for="(item,index) in timeArrShow" :key="index" :class="timeCheck == index ? 'checked' : ''" @click="timeCheck = index">
                    <p>{{item}}</p>
                    <i></i>
                  </a>
                </div>
              </div>
            </div>-->
            <div class="section" v-if="timeArr.length && !itineraryId">
              <div class="wrap">
                <h1>【乐园门票】需要选择入园时间</h1>
                <div class="list">
                  <a href="javascript:;" v-for="(item,index) in timeArr" :key="index" :class="gettimeCheckClass(item,index)" @click="timeCheckClick(item,index)">
                    <p>{{item}}</p>
                    <i></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="tips">
              当前时段为上行观光车乘车时间，下行乘车请在门票有效期内使用。
            </div>
          </div>
        </div>
        <div class="box contact" id="contact">
          <div class="title">
            <span>预订人信息</span
            ><a href="javascript:;" @click="showBooker" v-if="!itineraryId">使用已有预订人</a>
          </div>
          <div class="content">
            <div class="item">
              <div class="itemname">姓名<em>*</em></div>
              <div class="iteminfo inputbox">
                <el-input type="text" v-model="saveInfo.reservationPersonName" :disabled="itineraryId"></el-input>
              </div>
            </div>
            <div class="item">
              <div class="itemname">手机号<em>*</em></div>
              <div class="iteminfo inputbox">
                <el-input type="tel" name="" maxlength="11"  v-model="saveInfo.reservationPersonTelephone" :disabled="itineraryId"></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="box ticket">
          <div class="title"><span>预订票务明细</span></div>
          <!--<div class="bxtips">
            <p>
              即日起，峨眉山景区支持线上保险售卖，包括<span>游山票保险（5元保65万，购买峨眉山门票可购买）</span>和<span>索道保险（5元保65万，购买任意索道票可购买）</span>，
              每种险种每人最多可购买1份，特殊人群及6岁以下小孩请到线下窗口购买。</p>
            <p>购买保险前，请阅读<a href="" target="_blank"></a>及<a href="" target="_blank"></a>。</p>
          </div>-->
          <div class="content">
            <div class="formdata">
              <div class="databox" v-for="(item,index) in goodsInfo" :key="index">
                <div class="ticketstyle">
                  <div class="total">小计<em>￥{{item.sellPrice ? item.sellPrice*item.totalNum/100 : 0}}</em></div>
                  <div class="name">{{item.groupName}}</div>
                  <div class="number">
                    <i class="reduce" @click="reduce(item,index)">-</i>
                    <!--<el-input v-model="item.totalNum"></el-input>-->
                    <input type="text" v-model="item.totalNum" @change="changeRow(item,index)"/>
                    <i class="add" @click="add(item)">+</i>
                  </div>
                </div>
                <div class="up_down mt_20">
                  <a
                    href="./template/购票人列表.xlsx"
                    class="download"
                    target="_blank"
                    download
                    rel="noopener noreferrer"><i></i>下载模板</a>
                  <a href="javascript:;" class="upload" @click="uploadFile(item,index)"><i></i>消费者导入</a>
                  <input
                    type="file"
                    ref="fileInput"
                    id="file0"
                    @change="importExcel"
                    class="loadfile"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                </div>
                <div class="ticketinfo" v-for="(itm,idx) in item.reqOrderGroupDetailsShow" :key="idx">
                  <div class="up_down mb_20 d_f ali_c">
                    <span class="fs_15">{{itm.goodsName}}</span>
                  </div>
                  <div class="personlist">
                    <div class="row">
                      <div class="item">
                        <div class="itemname">姓名<em>*</em></div>
                        <div class="iteminfo inputbox">
                          <el-input v-model="itm.visitorName" :class="getClassXm(itm)"></el-input>
                        </div>
                      </div>
                      <div class="item">
                        <div class="itemname">证件类型<em>*</em></div>
                        <div class="iteminfo">
                          <el-select v-model="itm.certificateType" :class="getClassLx(itm)">
                            <el-option v-for="(ic,icx) in cardArr" :label="ic.name" :value="ic.value" :key="icx"></el-option>
                          </el-select>
                        </div>
                      </div>
                      <div class="item">
                        <div class="itemname">证件号码<em>*</em></div>
                        <div class="iteminfo inputbox">
                          <el-input v-model="itm.certificateNumber" :class="getClassZj(itm)"></el-input>
                        </div>
                      </div>
                      <a href="javascript:;" class="del" title="删除" @click="deleteRow(item,index,itm,idx)">删除</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="title mt_20"><span>提交类型选择<span class="red_t">(必选)</span></span></div>
            <div class="d_f ali_c j_s">
              <div class="radios d_f ali_c">
                <div class="cur_p" :class="itineraryType === 1 ? 'ac' : ''" @click="itineraryType =  1">
                  <span></span>
                  <span>提交为行程单</span>
                </div>
                <div class="cur_p" :class="itineraryType === 2 ? 'ac' : ''" @click="itineraryType =  2">
                  <span></span>
                  <span>提交为预约单</span>
                </div>
              </div>
            </div>
            <div class="btns">
              <span>总金额<em>￥{{totalPrice}}</em></span>
              <a href="javascript:;" @click="next">下一步</a>
              <a class="cancel" href="javascript:;" @click="cancel">取消</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--常用预订人-->
    <section class="popup" v-show="showContacts">
      <div class="box contact">
        <div class="title">
          <a href="javascript:;" title="关闭" @click="showContacts = false">关闭</a>
          <span>预订人列表</span>
        </div>
        <div class="content">
          <div class="query">
            <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
              <el-form-item label="姓名">
                <el-input placeholder="姓名" v-model="searchInfo.reservationPersonName"></el-input>
              </el-form-item>
              <el-form-item label="手机号">
                <el-input placeholder="手机号" v-model="searchInfo.phone"></el-input>
              </el-form-item>
              <el-form-item label-width="100px">
                <el-button @click="searchInfo = {reservationPersonName:'',phone:''}">重置</el-button>
                <el-button type="primary" @click="booker">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="list" style="max-height: 498.6px">
            <div class="t_l mb_12" v-if="chooseBooker.reservationPersonName">已选择预订人：{{chooseBooker.reservationPersonName + '   '+chooseBooker.phone+''}}</div>
            <el-table
              ref="multipleTable"
              :data="listBooker"
              :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
              tooltip-effect="dark"
              style="width: 100%"
              @current-change="handleCurrentChange"
              highlight-current-row
            >
              <el-table-column label="姓名" align="center">
                <template #default="scope">{{ scope.row.reservationPersonName }}</template>
              </el-table-column>
              <el-table-column prop="phone" label="手机号码" align="center"> </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="btns">
          <a class="enter" href="javascript:;" @click="bookerSure">确定</a
          ><a class="cancel" href="javascript:;" @click="cancelBooker">取消</a>
        </div>
      </div>
    </section>
    <!--导入购票人-->
    <section class="popup" v-show="showPersons">
      <div class="box persons">
        <div class="title">
          <a href="javascript:;" title="关闭" @click="showPersons = false">关闭</a>
          <span>消费者预览</span>
        </div>
        <div class="content">
          <div class="textinfo">
            <li>
              您共导入了<em class="all">{{xlsxJson.length}}</em>位游客信息，<em class="yes">{{sucNum}}</em>位导入成功，<em class="no">{{failNum}}</em>位导入失败。
            </li>
            <li>
              导入失败游客姓名有：<span v-for="(item,index) in xlsxJson" :key="index"><span v-if="!item.checkPass">{{item['姓名']}}</span></span>
            </li>
          </div>
          <div class="query">
            <div class="item">
              <div class="itemname">姓名</div>
              <div class="iteminfo"><input type="text" v-model="searchKey" placeholder="输入姓名进行搜索"/></div>
            </div>
          </div>
          <div class="list">
            <table>
              <thead>
              <tr class="gray">
                <th>姓名</th>
                <th>证件类型</th>
                <th>证件号码</th>
                <th>导入状态</th>
                <th>备注</th>
                <th>操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in xlsxJson" :key="index" :class="getClass(item,index)">
                <template v-if="item['姓名'].indexOf(searchKey)>-1">
                  <td><div class="inputbox"><input type="text" v-model="item['姓名']" class="uname" /></div></td>
                  <td>{{ item['证件类型']}}</td>
                  <td><div class="inputbox"><input type="text" v-model="item['证件号']" class="idcard" @change="delCheck(item)"/></div></td>
                  <td><i class="icon-state"></i></td>
                  <td v-if="!item.checkPass"><span class="no">{{item.checkText}}</span></td>
                  <td v-else><span>无</span></td>
                  <td><a href="javascript:;" class="icon-del" title="删除" @click="delUploadRow(index)">删除</a></td>
                </template>
              </tr>
              <!--<tr class="no gray">
                <td><div class="inputbox"><input type="text" value="周防尊" class="uname" /></div></td>
                <td>身份证</td>
                <td><div class="inputbox"><input type="text" value="11010120130307599X" class="idcard"/></div></td>
                <td><i class="icon-state"></i></td>
                <td><span class="no">该证件不符合游客类型</span></td>
                <td><a href="javascript:;" class="icon-del" title="删除">删除</a></td>
              </tr>
              <tr class="no">
                <td><div class="inputbox"><input type="text" value="一方通行" class="uname" /></div></td>
                <td>身份证</td>
                <td><div class="inputbox"><input type="text" value="110101201303075391" class="idcard"/></div></td>
                <td><i class="icon-state"></i></td>
                <td><span class="no">该证件不符合游客类型</span></td>
                <td><a href="javascript:;" class="icon-del" title="删除">删除</a></td>
              </tr>
              <tr class="no gray">
                <td><div class="inputbox"><input type="text" value="上条当麻" class="uname" /></div></td>
                <td>身份证</td>
                <td><div class="inputbox"><input type="text" value="110101194603079658" class="idcard"/></div></td>
                <td><i class="icon-state"></i></td>
                <td><span class="no">该证件不符合游客类型</span></td>
                <td><a href="javascript:;" class="icon-del" title="删除">删除</a></td>
              </tr>
              <tr class="no">
                <td>
                  <div class="inputbox">
                    <input type="text" value="夜" class="uname" />
                  </div>
                </td>
                <td>身份证</td>
                <td>
                  <div class="inputbox">
                    <input
                      type="text"
                      value="110101200003079393"
                      class="idcard"
                    />
                  </div>
                </td>
                <td><i class="icon-state"></i></td>
                <td><span class="no">身份证格式错误</span></td>
                <td>
                  <a href="javascript:;" class="icon-del" title="删除"
                  >删除</a
                  >
                </td>
              </tr>
              <tr class="yes gray">
                <td>
                  <div class="inputbox">
                    <input type="text" value="李林峰" class="uname" />
                  </div>
                </td>
                <td>身份证</td>
                <td>
                  <div class="inputbox">
                    <input
                      type="text"
                      value="511181199204214613"
                      class="idcard"
                    />
                  </div>
                </td>
                <td><i class="icon-state"></i></td>
                <td>无</td>
                <td>
                  <a href="javascript:;" class="icon-del" title="删除"
                  >删除</a
                  >
                </td>
              </tr>-->
              </tbody>
            </table>
          </div>
        </div>
        <div class="btns">
          <a class="enter" href="javascript:;" @click="sureUpPop">确定</a>
          <a class="cancel" href="javascript:;" @click="cancelUpPop">取消</a>
        </div>
      </div>
    </section>
    <!-- 购票人不足10人 -->
    <section class="popup" v-show="showLack">
      <div class="box">
        <div class="title">
          <a href="javascript:;" title="关闭" @click="showLack = false">关闭</a>
          <span>温馨提示</span>
        </div>
        <div class="content personlack">
          <div class="lack">
            <i></i>
            <p>非0元票未满10人，无法提交行程单</p>
            <p>请继续添加游客信息!</p>
            <a href="javascript:;" @click="showLack = false">继续添加</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import XLSX from 'xlsx'
  export default {
    name: "fillIn",
    data() {
      return {
        showContacts: false, // 显示常用预订人列表
        listBooker:[],// 常用预订人列表
        searchInfo:{reservationPersonName:'',phone:''},
        chooseBooker:{},
        chooseBookerSure:{},
        groupCommodityIds:[],
        goodsInfo:[],
        cardArr:[],//证件类型arr
        VisitorTypeArr:[],//游客类型arr
        showPersons: false, // 展示导入的购票人信息
        xlsxJson:[],//导入的数据json
        sucNum:0,//导入成功人数
        failNum:0,//导入失败人数
        searchKey:'',//导入弹窗搜索的key
        uploadRowType:'',//导入的那一列的游客类型
        uploadRowIndex:null,//导入的那一列的最外面index
        uploadRowName:null,//导入的那一列的title
        uploadRowIdx:null,//导入的那一列的里面index
        stockDate:'',
        showLack: false, // 购票人不足10人
        groupAndBeginEndTime:{},
        timeId:[],//时段id
        timeArr:[],//时段
        timeCheck: null,//选中时段index
        defaultDate:'',
        saveInfo:{
          "reservationPersonName": "",
          "reservationPersonTelephone": "",
          "cardType": "",
          "cardNo": "",
          "bookUseDate": '',
          "source": "2",
          "orderType": "1",
          "reqOrderGroupDetails": [
            /*{
              "groupCommodityId": "94",
              "groupCode": "76",
              "groupName": "百再回片",
              "reqOrderDetails": {
                "visitorName": "南半且就区",
                "certificateType": "in reprehenderit occaecat qui id",
                "certificateNumber": "88",
                "commodityId": "89",
                "groupCommodityDetailId": "69",
                "startTime": "2019-01-18 00:00:00",
                "endTime": "1984-11-18 22:39:54",
                "bookUseDate": "2019-12-20"
              }
            }*/
          ]
        },
        // 修改逻辑
        itineraryId:'',
        infoChange:{itineraryDetails:[]},
        ticketStocks:[],
        itineraryType:1,//1行程单 2预约单
      };
    },
    computed: {
      //时间段时间判断
      timeArrShow: function() {
        let arr = [];
        this.timeArr.map(item => {
          let str = this.saveInfo.bookUseDate+ ' ' + item.split(' - ')[1];
          if(new Date(str).getTime() > new Date().getTime()){
            arr.push(item)
          }
        });
        return arr
      },
      totalPrice: function () {
        let pri = 0;
        this.goodsInfo.map(item => {
          if(item.sellPrice){
            pri += item.sellPrice * item.totalNum / 100
          }
        })
        return pri
      }
    },
    created(){
      this.itineraryType = sessionStorage.getItem('itineraryType') ? Number(sessionStorage.getItem('itineraryType')) : 1;
      this.groupCommodityIds = this.$route.query.ids ? this.$route.query.ids.split(',') : '';
      this.itineraryId = this.$route.query.id;
      this.queryWinCredentials();
      this.queryWinVisitorType();
      let date = new Date();
      let m = date.getMonth()+1;
      let d = date.getDate();
      if(m < 10){
        m = '0'+ m
      }
      if(d < 10){
        d = '0'+ d
      }
      this.defaultDate = date.getFullYear()+'-'+m+'-'+d;
      this.saveInfo.bookUseDate = this.defaultDate;
      if(this.groupCommodityIds){
        if(sessionStorage.getItem('saveInfo')){
          this.saveInfo = JSON.parse(sessionStorage.getItem('saveInfo'))
        }
        if(sessionStorage.getItem('timeCheck')){
          this.timeCheck = sessionStorage.getItem('timeCheck') ? Number(sessionStorage.getItem('timeCheck')) : null;
        }
        if(sessionStorage.getItem('goodsInfo')){
          this.goodsInfo = JSON.parse(sessionStorage.getItem('goodsInfo'));
          this.queryDisGroupAndCommditys(1);
        }else{
          this.queryDisGroupAndCommditys();
        }
      }
      if(this.itineraryId){
        this.getInfoChange()
      }
    },
    methods: {
      timeCheckClick(row,index){
        let classStr = '';
        let str = this.saveInfo.bookUseDate+ ' ' + row.split(' - ')[1];
        if(new Date(str).getTime() < new Date().getTime()){
          // return this.$message.error('该时段不可选！')
        }else{
          this.timeCheck = index
        }
      },
      gettimeCheckClass(row,index){
        let classStr = '';
        let str = this.saveInfo.bookUseDate+ ' ' + row.split(' - ')[1];
        if(new Date(str).getTime() < new Date().getTime()){
          classStr = 'dis_timeCheck'
        }else{
          if(this.timeCheck == index){
            classStr = ' checked'
          }
        }
        return classStr
      },
      checkPhone (value){
        let reg = /^1[345789]\d{9}$/;
        if (!reg.test(value)) {
          return false
        } else {
          return true
        }
      },

      getInfoChange(){
        this.$api.queryItineraryGroup({itineraryId :this.$route.query.id}).then(res => {
          this.infoChange = res.data;
          this.saveInfo.bookUseDate = this.infoChange.ticketItinerary.bookUseDate;
          this.saveInfo.reservationPersonName = this.infoChange.ticketItinerary.reservationPersonName;
          this.saveInfo.reservationPersonTelephone = this.infoChange.ticketItinerary.reservationPersonTelephone;
          this.saveInfo.cardNo = this.infoChange.ticketItinerary.cardNo;
          this.saveInfo.cardType = this.infoChange.ticketItinerary.cardType;
          let ids = [];
          this.infoChange.orderDetails.map(item => {
            ids.push(item.groupCommodityId)
          });
          let showClass = [];
          this.infoChange.itineraryDetails.map(item => {
            if(showClass.indexOf(item.itineraryGroupDetailId) < 0){
              showClass.push(item.itineraryGroupDetailId)
            }
          });
          let showClassInfo = [];
          this.infoChange.orderDetails.map(itm => {
            let isPush = true;
            let secondObj = {...itm};
            this.infoChange.itineraryDetails.map(item => {
              if(itm.itineraryGroupDetailId === item.itineraryGroupDetailId){
                if(isPush){
                  secondObj.itineraryDetails = {...item}
                  showClassInfo.push(secondObj)
                }
                isPush = false;
              }
            });
          });
          this.infoChange.showClassInfo = [...showClassInfo];
          console.log(this.infoChange)
          this.groupCommodityIds = ids;
          this.itineraryType = res.data.ticketItinerary.itineraryType;
          this.queryDisGroupAndCommditys();
        })

      },
      groupArr(array, subGroupLength) {
        let index = 0;
        let newArray = [];
        while(index < array.length) {
          newArray.push(array.slice(index, index += subGroupLength));
        }
        return newArray;
      },
      // 下一步
      next() {
        // this.showLack = true  // 显示非0元票不足10人提示
        let _t = this;
        if(!this.itineraryId){
          if(_t.timeArr.length && (_t.timeCheck === null || _t.timeCheck === 'NaN')){
            stepbox.scrollIntoView();
            return _t.$message.error('请选择入园时间');
          }
        }
        if(!_t.saveInfo.reservationPersonName || !_t.saveInfo.reservationPersonTelephone) {
          stepbox.scrollIntoView();
          return _t.$message.error('预订人信息不全');
        }
        if(!_t.checkPhone(_t.saveInfo.reservationPersonTelephone)){
          stepbox.scrollIntoView();
          return _t.$message.error('预订人电话有误');

        }
        let isPass = true;
        console.log(_t.timeArrShow);
        console.log(_t.timeCheck);
        let time = '';
        if(_t.timeArrShow.length && _t.timeCheck !== null){
          time = _t.timeArrShow[_t.timeCheck];
        }
        let delArr = [];
        let stocks = true;
        let stocksStr = '';
        this.goodsInfo.map((item,index) => {
          item.reqOrderGroupDetails.map((itm,idx) => {
            if(!itm.ticketStocks.length){
              stocks = false;
              stocksStr = itm.commodityName + '无库存！'
            }
            itm.ticketStocks.map((ti,iddx) => {
              if(!ti.beginTime){
                if(ti.surplus < item.totalNum * itm.num){
                  stocks = false;
                  stocksStr = itm.commodityName + '库存不足！'
                }
              }else{
                if(time){
                  if(ti.beginTime == time.split(' - ')[0]){
                    if(ti.surplus < item.totalNum * itm.num){
                      stocks = false;
                      stocksStr = itm.commodityName + '库存不足！'
                    }
                  }
                }
              }
            })
          });
        });
        if(!stocks) return _t.$message.error(stocksStr);

        this.goodsInfo.map((item,index) =>{
          item.reqOrderGroupDetailsShow.map((itm,idx) => {
            if(!itm.certificateNumber || !itm.visitorName){
              isPass = false;
            }
          })
        });
        this.goodsInfo.map((item,index) =>{
          item.reqOrderGroupDetailsShow.map((itm,idx) => {
            delArr.push({
              "groupCommodityId": item.groupCommodityId,
              "groupCode": item.groupCode,
              "groupName": item.groupName,
              "certificateNumber": itm.certificateNumber,
              "certificateType": itm.certificateType,
              "startTime": time ? time.split(' - ')[0] : '',
              "visitorName": itm.visitorName,
              "endTime": time ? time.split(' - ')[1] : '',
              "reqOrderDetails": [],
              "groupDetailId": itm.itineraryGroupDetailId ? itm.itineraryGroupDetailId : ''
            })
          })
        });
        if(isPass){
          this.saveInfo.reqOrderGroupDetails = JSON.parse(JSON.stringify(delArr));
        }else{
          return _t.$message.error('游客信息不完整！');
        }
        console.log(this.saveInfo)
        if(this.itineraryId){
          this.saveInfo.serialNo = this.infoChange.ticketItinerary.serialNo;
          this.saveInfo.itineraryId = this.itineraryId;
          this.saveInfo.itineraryType = this.itineraryType;
          this.$api.modityTicket(this.saveInfo).then(res => {
            // this.$message.success('提交成功');
            sessionStorage.setItem('goodsInfo',JSON.stringify(this.goodsInfo));
            sessionStorage.setItem('saveInfo',JSON.stringify(this.saveInfo));
            if(this.timeCheck){
              sessionStorage.setItem('timeCheck',this.timeCheck);
              sessionStorage.setItem('time',time);
            }
            sessionStorage.setItem('itineraryType',this.itineraryType);
            this.$router.push({path:'/submitOrder',query:{id: res.data,itineraryType:this.itineraryType}})
          })
        }else{
          this.saveInfo.itineraryType = this.itineraryType;
          this.$api.createTicket(this.saveInfo).then(res => {
            // this.$message.success('提交成功');
            sessionStorage.setItem('goodsInfo',JSON.stringify(this.goodsInfo));
            sessionStorage.setItem('saveInfo',JSON.stringify(this.saveInfo));
            if(this.timeCheck){
              sessionStorage.setItem('timeCheck',this.timeCheck);
              sessionStorage.setItem('time',time);
            }
            sessionStorage.setItem('itineraryType',this.itineraryType);
            this.$router.push({path:'/submitOrder',query:{id: res.data,itineraryType:this.itineraryType}})
          })
        }
      },
      // 取消填写
      cancel() {
        if(this.itineraryId){
          this.$api.itineraryCancel({itineraryId:this.itineraryId}).then(res => {
            this.$message.success('取消成功');
            this.$router.go(-1)
          })
        }else{

          this.$router.go(-1)
        }
      },
      checkRepeatName (arr) {
        const obj = {}
        let flag = arr.every(item =>{
          return obj[item.certificateNumber] ? false : obj[item.certificateNumber] = true
        })
        return flag
      },
      //删除游客信息
      deleteRow(item,index,itm,idx){
        item.totalNum -= 1;
        item.reqOrderGroupDetailsShow.splice(idx,1)
      },
      // 根据产品ID返回产品信息及商品明细(及价格)
      queryDisGroupAndCommditys(num){
        let _t = this;
        let send = {
          beginTime:'',
          endTime:'',
          groupCommodityId:this.groupCommodityIds,
          stockDate:this.saveInfo.bookUseDate.split(' ')[0],
        };
        this.$api.queryDisGroupAndCommditys(send).then(res => {
          let info = res.data;
          let tiInfo = [];
          // this.ticketStocks = info
          info.map((item,index) => {
            if(item.ticketCommodities.length){
              if(JSON.stringify(item.ticketGroupCommodity.groupAndBeginEndTime) != '{}'){
                let obj = item.ticketGroupCommodity.groupAndBeginEndTime;
                for (let key in obj) {
                  if(obj[key].length>0){
                    _t.timeId.push(key);
                    _t.timeArr = obj[key];
                  }
                }
              }
              // if(JSON.stringify(item.ticketGroupCommodity.groupAndBeginEndTime) != '{}' && item.ticketGroupCommodity.groupAndBeginEndTime[Object.keys(item.ticketGroupCommodity.groupAndBeginEndTime)].length > 0) this.groupAndBeginEndTime = item.ticketGroupCommodity.groupAndBeginEndTime;
              tiInfo[index] = {
                // totalNum:0,
                totalNum:1,
                // ticketStocks:item.ticketGroupCommodity.ticketStocks,
                sellPrice:item.ticketGroupCommodity.sellPrice,
                groupCommodityId:item.ticketGroupCommodity.groupCommodityId,
                groupCode:item.ticketGroupCommodity.groupCode,
                groupName:item.ticketGroupCommodity.groupName,
                reqOrderGroupDetails:[],
              };
              item.ticketCommodities.map((itm,idx) => {
                let num = tiInfo[index].totalNum * itm.num;
                tiInfo[index].reqOrderGroupDetails[idx] = itm;
                tiInfo[index].reqOrderGroupDetails[idx].ticketStocks = [];
                item.ticketGroupCommodity.ticketStocks.map((stock,stockIndex) => {
                  if(itm.commodityDefineId == stock.commodityDefineId){
                    tiInfo[index].reqOrderGroupDetails[idx].ticketStocks.push(stock)
                  }
                });
                tiInfo[index].reqOrderGroupDetails[idx].user = [];
              })
            }
          });
          console.log(_t.timeId)
          console.log(_t.timeArr)
          for(let i = 0;i< tiInfo.length;i++) {
            if(!tiInfo[i]){
              tiInfo.splice(i,1);
              i--;
            }
          }
          if(this.infoChange.orderDetails){
            tiInfo.map(itm => {
              itm.totalNum = 0
            });
            this.infoChange.orderDetails.map(item => {
              tiInfo.map(itm => {
                if(item.groupCode === itm.groupCode){
                  itm.totalNum++;
                }
              })
            });
          }
          tiInfo.map(itm => {
            itm.reqOrderGroupDetails.map(im => {
              let forNum = itm.totalNum * im.num;
              for(let i = 0;i<forNum;i++){
                im.user.push ( {
                  "visitorName": "",
                  "certificateType": "",
                  "certificateNumber": "",
                  "commodityId": im.commodityId,
                  "groupCommodityDetailId": im.groupCommodityDetailId,
                  "startTime": "",
                  "endTime": "",
                  "bookUseDate": "",
                  "itineraryDetailId": "",
                })
              }
            })
          });
          let itineraryDetailIdPush = {};
          if(this.infoChange.itineraryDetails.length){
            this.infoChange.itineraryDetails.map(im => {
              itineraryDetailIdPush[im.itineraryDetailId] = false;
            })
          }
          if(this.infoChange.itineraryDetails.length){
            tiInfo.map(imm => {
              imm.reqOrderGroupDetails.map((item,index) => {
                this.infoChange.itineraryDetails.map(im => {
                  item.user.map(itm => {
                    if(itm.commodityId == im.commodityId && !itm.itineraryDetailId && !itineraryDetailIdPush[im.itineraryDetailId]){
                      itm.itineraryDetailId = im.itineraryDetailId;
                      itm.itineraryGroupDetailId = im.itineraryGroupDetailId;
                      itineraryDetailIdPush[im.itineraryDetailId] = true
                    }
                  })
                })
              })
            })
          }
          if(!num){
            this.goodsInfo = JSON.parse(JSON.stringify(tiInfo));
          }
          if(this.infoChange.itineraryDetails.length){
            this.goodsInfo.map(imm => {
              imm.reqOrderGroupDetails.map((item,index) => {
                item.user.map(itm => {
                  this.infoChange.itineraryDetails.map(im => {
                    if(itm.itineraryDetailId == im.itineraryDetailId){
                      itm.visitorName = im.visitorName;
                      itm.certificateNumber = im.certificateNumber;
                      itm.certificateType = im.credentialsType.toString();
                    }
                  })
                })
              })
            })
          }

          //修改
          if( this.infoChange.showClassInfo && this.infoChange.showClassInfo.length){
            let obj = {};
            this.infoChange.showClassInfo.map(itm => {
              let pushInfo = {
                visitorName : itm.itineraryDetails.visitorName,
                certificateType : itm.itineraryDetails.credentialsType+'',
                certificateNumber : itm.itineraryDetails.certificateNumber,
                itineraryGroupDetailId : itm.itineraryGroupDetailId,
              };
              if(obj[itm.groupCommodityId]){
                obj[itm.groupCommodityId].push(pushInfo)
              }else{
                obj[itm.groupCommodityId] = [pushInfo]
              }
            });
            this.goodsInfo.map(item => {
              let goodsName = '';
              item.reqOrderGroupDetails.map(itm => {
                goodsName += ' · ' + itm.commodityName + '（' + itm.num + '份）'
              });
              item.goodsName = goodsName;
              item.reqOrderGroupDetailsShow = [...obj[item.groupCommodityId]];
            });
            this.goodsInfo.map(item => {
              item.reqOrderGroupDetailsShow.map(itm => {
                itm.goodsName = item.goodsName
              })
            });
          }else{
            //新增
            if(!num){
              this.goodsInfo.map(item => {
                item.reqOrderGroupDetailsShow = [];
                let obj = {
                  goodsName:'',
                  visitorName:'',
                  certificateType:'1',
                  certificateNumber:'',
                };
                let goodsName = '';
                item.reqOrderGroupDetails.map(itm => {
                  goodsName += ' · ' + itm.commodityName + '（' + itm.num + '份）'
                });
                obj.goodsName = goodsName;
                for(let i = 0 ; i < item.totalNum; i++){
                  item.reqOrderGroupDetailsShow.push(obj)
                }
              });
            }
          }
          // this.infoChange.showClassInfo.map(item)
          console.log(this.goodsInfo)
        })
      },
      //获取姓名class
      getClassXm(row){
        if(!row.visitorName) return ' error'
      },
      //获取证件类型class
      getClassLx(row){
        if(!row.certificateType) return ' error'

      },
      //获取证件号class
      getClassZj(row){
        let pass = false;
        if(row.certificateNumber){
          if(row.certificateType){
            let type = '';
            this.cardArr.map(item => {
              if(item.value == row.certificateType){
                type = item.name
              }
            });
            if(type == '身份证'){
              if(this.$global.validateIdCard(row.certificateNumber)){
                pass = true;
              }
            }else{
              pass = true;
            }
          }else{
            pass = true;
          }
        }
        if(!pass){
          row.pass = false;
          return ' error'
        }else{
          row.pass = true;
        }
      },
      //禁用今天之前的日期
      disabledDate(time) {
        let date1 = new Date();
        let date2 = new Date(date1);
        let str = '';
        date2.setDate(date1.getDate() + 30);
        let m = date2.getMonth() + 1 < 10 ? '0' + Number(date2.getMonth() + 1) : date2.getMonth() + 1;
        let d = date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate();
        str = date2.getFullYear() + "-" + m + "-" + d + ' 00:00:00';
        return time.getTime() < new Date(this.defaultDate+' 00:00:00').getTime() || time.getTime() > new Date(str).getTime()
      },
      // 增加购票人
      add(row) {
        row.totalNum++;
        this.delGoodsInfo(row);
      },
      changeRow(row,index){
        this.reduce(row,index,1)
      },
      // 减少购票人
      reduce(row,index,reduce) {
        let _t = this;
        if(!reduce){
          row.totalNum--;
        }
        if(row.totalNum<1){
          this.$messageBox.confirm(
            '确认删除?',
            '提示',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning',
            }
          ).then(() => {
            row.reqOrderGroupDetails.map(item => {
              if(_t.timeId.indexOf(item.commodityDefineId.toString()) > -1){
                _t.timeArr = [];
                _t.timeArrShow = [];
              }
            });
            _t.goodsInfo.splice(index,1);
          }).catch(() => {
            row.totalNum = 1;
            _t.delGoodsInfo(row);
          })
        }else{
          _t.delGoodsInfo(row);
        }
      },
      delGoodsInfo(row){
        row.reqOrderGroupDetails.map((item,index) => {
          let l = item.user.length;
          let n = row.totalNum*item.num;
          if(l > n){
            item.user.splice(n,l)
          }else{
            let num = n-l;
            for(let i = 0;i<num;i++){
              item.user.push ( {
                "visitorName": "",
                "certificateType": "",
                "certificateNumber": "",
                "commodityId": item.commodityId,
                "groupCommodityDetailId": item.groupCommodityDetailId,
                "startTime": "",
                "endTime": "",
                "bookUseDate": "",
                "itineraryDetailId": "",
              })
            }
          }
        });
        let nowL = row.reqOrderGroupDetailsShow.length;
        if(nowL > row.totalNum){
          row.reqOrderGroupDetailsShow.splice(row.totalNum,nowL)
        }else{
          let num = row.totalNum - nowL;
          let delShow = {...row.reqOrderGroupDetailsShow[0]};
          delShow.certificateNumber = "";
          delShow.certificateType = "1";
          delShow.visitorName = "";
          for(let i = 0;i<num;i++){
            row.reqOrderGroupDetailsShow.push (delShow)
          }
        }
        this.goodsInfo = JSON.parse(JSON.stringify(this.goodsInfo));
        console.log(this.goodsInfo)
      },
      // 点击上传文件
      uploadFile(row,inx) {
        /*let type = inrow.visitorTypeId;
        this.VisitorTypeArr.map(item => {
          if(Number(item.visitorTypeId) == type){
            this.uploadRowType = item.endAge
          }
        });*/
        this.uploadRowIndex = inx;
        // this.uploadRowIdx = idx;
        var fileInput = document.getElementById("file0");
        fileInput.click();
      },
      //导入弹窗确认
      sureUpPop(){
        let passArr = [];
        this.xlsxJson.map(item => {
          if(item.checkPass){
            passArr.push({
              "visitorName": item['姓名'],
              "certificateType": this.getCardType(item['证件类型']),
              "certificateNumber": item['证件号'],
              "goodsName": this.goodsInfo[this.uploadRowIndex].reqOrderGroupDetailsShow[0].goodsName,
            })
          }
        });
        let user = [...this.goodsInfo[this.uploadRowIndex].reqOrderGroupDetailsShow];
        user = user.concat(passArr);
        for(let i = 0;i<user.length;i++){
          if(!user[i].visitorName){
            user.splice(i,1);
            i--
          }
        }
        this.goodsInfo[this.uploadRowIndex].totalNum = user.length;
        this.goodsInfo[this.uploadRowIndex].reqOrderGroupDetailsShow = [...user];

        this.cancelUpPop()
      },
      //通过证件类型获取证件类型value
      getCardType(name){
        let type = '';
        this.cardArr.map(item => {
          if(item.name == name){
            type = item.value
          }
        });
        return type
      },
      //导入弹窗取消
      cancelUpPop(){
        this.showPersons = false;
        this.xlsxJson = [];
        this.sucNum = 0;
        this.failNum = 0;
      },
      //删除导入数据
      delUploadRow(index){
        this.$messageBox.confirm(
          '确认删除?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            this.xlsxJson.splice(index,1)
          })

      },
      delCheck(){
        this.delXlsxJson(this.xlsxJson)
      },
      //验证导入数据
      delXlsxJson(sheet){
        sheet.map( item => {
          item.checkPass = true;
          item.checkText = '';
          if(item['证件类型'] == '身份证'){
            if(!this.$global.validateIdCard(item['证件号'])){
              item.checkPass = false;
              item.checkText = '身份证格式错误';
            }
            if (item.checkPass){
              if(this.uploadRowType == 18){
                if(this.$global.calcAge(item['证件号']) > 18){
                  item.checkPass = false;
                  item.checkText = '该证件不符合游客类型';
                }
              }
            }
          }
        });
        this.xlsxJson = JSON.parse(JSON.stringify(sheet));
        this.countNum();
        this.showPersons = true;
      },
      //计算成功失败人数
      countNum(){
        this.sucNum = 0;
        this.failNum = 0;
        this.xlsxJson.map(item => {
          if(item.checkPass){
            this.sucNum++
          }else{
            this.failNum++
          }
        });

      },
      //导入函数
      importExcel(fil) {
        let _t = this;
        let file = fil.target.files[0];
        const types = file.name.split('.')[1]
        const fileType = ['xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'].some(item => item === types)
        if (!fileType) {
          _t.$message.error('格式错误！请重新选择')
          return
        }
        this.file2Xce(file).then(tabJson => {
          if (tabJson && tabJson.length > 0) {
            this.$refs.fileInput.value = '';
            document.getElementById("file0").value = null;
            if(tabJson[0].sheet.length>0){
              this.delXlsxJson(tabJson[0].sheet);
              this.showPersons = true;
            }

            // xlsxJson就是解析出来的json数据,数据格式如下
            // [
            //   {
            //     sheetName: sheet1
            //     sheet: sheetData
            //   }
            // ]
          }
        })
      },
      file2Xce(file) {
        return new Promise(function(resolve, reject) {
          const reader = new FileReader()
          reader.onload = function(e) {
            const data = e.target.result
            this.wb = XLSX.read(data, {
              type: 'binary'
            })
            const result = []
            this.wb.SheetNames.forEach((sheetName) => {
              result.push({
                sheetName: sheetName,
                sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
              })
            })
            resolve(result)
          }
          // reader.readAsBinaryString(file.raw)
          reader.readAsBinaryString(file) // 传统input方法
        })
      },
      //获取导入的class
      getClass(row,index){
        let str = '';
        if(!row.checkPass){
          str = ' no '
        }else{
          str = ' yes  '
        }
        if(index%2 == 1){
          str += ' gray '
        }
        return str
      },
      //获取证件类型
      queryWinCredentials(){
        this.$api.queryWinCredentials().then(res => {
          this.cardArr = res.data;
        })
      },
      //获取游客类型
      queryWinVisitorType(){
        this.$api.queryWinVisitorType().then(res => {
          this.VisitorTypeArr = res.data;
        })
      },
      //预订人信息处理
      bookerSure(){
        this.showContacts = false;
        this.saveInfo.reservationPersonName = this.chooseBooker.reservationPersonName;
        this.saveInfo.reservationPersonTelephone = this.chooseBooker.phone;
        this.saveInfo.cardType = this.chooseBooker.credentialsType;
        this.saveInfo.cardNo = this.chooseBooker.certificateNumber;
      },
      cancelBooker(){
        this.showContacts = false;
        this.chooseBooker = {}
      },
      handleCurrentChange(val) {
        this.chooseBooker = JSON.parse(JSON.stringify(val))
      },
      showBooker(){
        this.showContacts = true;
        this.booker();
      },
      //获取预订人列表
      booker(){
        this.$api.searchList(this.searchInfo).then(res => {
          this.listBooker = res.data;
        })
      }
    }
  };
</script>

<style scoped>
  @import "../assets/css/step.css";
  @import "../assets/css/fillIn.css";
</style>
